import { testIdentifiers } from '../consts'
/**
 *
 * @param {string} testIdentifier - the internal test identifier
 * @returns {boolean}
 */
export const checkIsSSPO = (testIdentifier) =>
  [
    testIdentifiers.SSPO_A,
    testIdentifiers.SSPO_B,
    testIdentifiers.SSPO_C,
    testIdentifiers.SSPO_D,
  ].includes(testIdentifier)
