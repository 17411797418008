const order = {
  MAB_II: 0,
  MAB_III: 1,
  NEO_B: 2,
  NEO_C: 3,
  SSPO_A: 4,
  SSPO_B: 5,
  SSPO_C: 6,
  SSPO_D: 7,
  PRFR_POLICE: 8,
  PRFR_OPT: 9,
}

export function orderTests(a, b) {
  // used to sort tests until backend ordering is implemented
  if (a.hasOwnProperty('test')) {
    return (
      order[a.test.internalTestIdentifier] -
      order[b.test.internalTestIdentifier]
    )
  }

  return order[a.internalTestIdentifier] - order[b.internalTestIdentifier]
}

export function orderMenuOptions(a, b) {
  return order[a.internalTestIdentifier] - order[b.internalTestIdentifier]
}
