import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import customLanguageDetector from './custom-language-detector'

const isBrowser = typeof window !== 'undefined'

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customLanguageDetector)

export const supportedLngs = ['en-US', 'en-CA', 'fr']
export const fallbackLng = 'en-US'

if (isBrowser) {
  i18next
    .use(ChainedBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      supportedLngs,
      ns: ['common', 'tests', 'admin', 'tntAdmin'],
      defaultNS: 'tests',
      fallbackLng,
      preload: false,
      react: { useSuspense: false },
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            expirationTime:
              process.env.NODE_ENV !== 'production' ? 60 : 24 * 60 * 60 * 1000,
          },
          {
            loadPath: process.env.NEXT_PUBLIC_LOCALIZATION_URL,
            crossDomain: true,
          },
        ],
      },
      detection: {
        order: [
          'stateDetector',
          'querystring',
          'cookie',
          'localStorage',
          'sessionStorage',
          'navigator',
        ],
        caches: ['stateDetector', 'cookie', 'localStorage', 'sessionStorage'],
      },
    })
}

export default i18next
